<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";


//import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
import Choices from "choices.js";
import moment from 'moment'
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import ProgressDialog from './components/progress_dialog.vue'

import { required, } from "vuelidate/lib/validators";
import { getPMApi } from '@/api/pm'
import { autoComplete } from '@/api/misc'
import { getBrokerageApi } from '@/api/brokerage'



/**
 * Setup PM  component
 */
export default {
  page: {
    title: "PM",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },

  validations: {
    pm_month: { required },
    selected_amount: { required },
    selected_pm: { required },
    post_date: { required },
    pad_run_date: { required },
    selected_tenant : {required},
  },

  computed: {
    download_pad_btn_flag() {
      return this.disable_download_btn_flag
    }
  },


  methods: {

    formSubmit() {

      this.$v.pad_run_date.$touch()
      this.$v.post_date.$touch()
      this.$v.pm_month.$touch()
      if (this.$v.pad_run_date.$invalid || this.$v.post_date.$invalid || this.$v.pm_month.$invalid) {
        return
      }

      this.$alertify.confirm(
        "Are you sure to charge rent by PAD?",
        () => {

          this.listingData.map(pm => {
            if (pm.rent_items) {
              pm.rent_items.map(it => {
                it.post_date = this.post_date
              })
            }
          })

          let data = {
            rent_month: this.pm_month,
            properties: this.listingData,
            pad_run_date: this.pad_run_date,
            post_date: this.post_date
          }

          this.$bvModal.show('ProgressDiloag')

          getPMApi().setup_pm_pad_file(data).then(res => {
            if (res.errCode == 0) {
              this.$alertify.message("Setup  Rent PAD File Success");
              this.disable_download_btn_flag = false
              this.download_url = res.data.url

            } else {
              this.$alertify.error(" Property  Rent deposit failed:" + res.errCode);
            }
            this.$bvModal.hide('ProgressDiloag')
          })
        },
        () => {
        }
      );




    },




    removePMEntry(data) {
      this.listingData = this.listingData.filter(e => data.pm_id != e.pm_id)
      this.updateAccountAmount()
    },



    pm_list(property_id) {
      let data = {
        payment_type: 'PAD'
      }
      if (property_id) {
        data.property_id = property_id
      }
      getPMApi().query_pm_list_by_payment_type(data).then(res => {
        if (res.errCode == 0) {
          this.listingData = []
          res.data.map(e => {
            this.listingData.push({
              pm_id: e.property_id,
              property_id: e.property_id,
              amount: e.monthly_rent,
              address: e.address,
              tenant: e.t_name,
              rent_month: this.pm_month,
              extra_tenant_id: e.extra_tenant_id,
              rent_items: [{
                amount: e.monthly_rent,
                type: this.selected_type,
                post_date: moment().format("YYYY-MM-DD")
              }]

            })
          })
          this.updateAccountAmount()
        }
      })
    },


    updateRentAmount(data) {
      this.$alertify.prompt(
        "This is a prompt dialog.",
        data.amount,
        (evt, value) => {
          data.amount = value
          this.updateAccountAmount()
        },
        () => {
          this.$alertify.error("Cancel");
        }
      );
    },

    setup_pad_form_file() {

      getPMApi().generate_pad_file().then(res => {
        if (res.errCode == 0) {
          window.open(res.data.url, "_blank")
        } else {
          this.$alertify.error("Download File failed ");
        }
      })

    },

    download_pad_file() {
      window.open(this.download_url, "_blank")
    },


    updateAccountAmount() {
      let pm_trust_bank_gl = {
        account: this.pm_trust_bank.gl_account,
        amount: 0
      }

      this.pad_total_amount = 0

      this.accounting_list = []
      this.accounting_list.push(pm_trust_bank_gl)
      this.listingData.map(e => {
        pm_trust_bank_gl.amount += e.amount
        this.pad_total_amount   += e.amount
        this.accounting_list.push({
          account: this.pm_trust_bank.gl_liability_account,
          pm_id: e.property_id,
          property_id: e.property_id,
          amount: -e.amount,
          address: e.address,

        })

      })
    },

    onAddProperty() {
      this.$v.selected_pm.$touch()
      this.$v.selected_amount.$touch()
      this.$v.selected_tenant.$touch()
      if (this.$v.selected_amount.$invalid == true || this.$v.selected_pm.$invalid == true || this.$v.selected_tenant.$invalid == true) {
        return
      }

      let pm = this.searched_pm_data.find(e => e.property_id == this.selected_pm)

      let cache = this.listingData.find(e => e.pm_id == pm.property_id && e.tenant_id == this.selected_tenant_obj.id)

      if (!cache) {
        cache = {
          code       : this.selected_pm,
          pm_id      : this.selected_pm,
          property_id: this.selected_pm,
          tenant     : this.selected_tenant,
          tenant_id  : -1,
          rent_month : this.pm_month,
          amount     : 0,
          address    : pm.address,
          rent_items : []
        }

        if ( this.selected_tenant_obj.extra_tenant == true) {
          cache.extra_tenant_id = this.selected_tenant_obj.id
          cache.tenant_id       = this.selected_tenant_obj.id
        }
        
        this.listingData.push(cache)
      }

      


      cache.rent_items.push({ amount: this.selected_amount, type: this.selected_type, pm_month: this.pm_month, note: this.note })
      cache.amount += Number(this.selected_amount)
      this.updateAccountAmount()
      this.$alertify.message("Property Rent Item was Added to List");
    },

    onPMSelected(evt) {
      this.selected_pm = evt.str_id
      this.get_pm_tenants()
    },

    onSearchPM(str) {
      autoComplete({ types: ['PM'], str: str, selection: ['property_id', 'o_code', 'address', 't_name', 'extra_tenant'] }).then(res => {
        if (res.errCode == 0) {
          this.searched_pm_data = []
          res.data.map(e => {
            this.searched_pm_data.push(e)
          })

         
        }
      })
    },

    removePMRentItem(pmItem, data) {
      let item = pmItem.rent_items[data.index]
      pmItem.amount -= item.amount
      pmItem.rent_items.splice(data.index, 1)
      if (pmItem.rent_items.length <= 0) {
        this.listingData = this.listingData.filter(e => e.pm_id != pmItem.pm_id)
      }

      this.updateAccountAmount()
    },

    list_sort() {
      this.listingData.sort((a, b) => {
        if (this.sortBy == 'Code') {
          if (this.sortDesc) {
            return a.pm_id > b.pm_id ? -1 : 1
          } else {
            return a.pm_id > b.pm_id ? 1 : -1
          }
        }
      })
    },

    get_pm_tenants() {

      getPMApi().get_rta_tenants({pm_id : this.selected_pm}).then(res => {
        if (res.errCode == 0) {
          this.tenant_list = []
          this.tenant_list.push(...res.data)
          if (this.tenant_list.length ==1) {
            this.selected_tenant = this.tenant_list[0].name
            this.$refs['selected_tenant'].inputValue = this.selected_tenant
            this.selected_tenant_obj.extra_tenant    = false
            this.selected_tenant_obj.extra_tenant_id = -1
          }  else {
            this.selected_tenant = ''
            this.$refs['selected_tenant'].inputValue = this.selected_tenant
          }
        }
      })
    },

    onTenantSelected(evt) {
      this.selected_tenant_obj = evt
      console.log(this.selected_tenant_obj)
    }

  },

  data() {
    return {
      title: "PM",
      items: [
        {
          text: "PM List",
          href: "/pm/property_list",
        },
        {
          text: "Setup PM Rent PAD",
          active: true,
        },
      ],


      filter: '',
      file: {},
      transfer_date: '',
      pm_month: '',
      selected_pm: '',
      selected_amount: '',
      selected_type: 'Rent',
      note: '',
      sortDesc: '',
      sortBy: '',
      post_date: '',
      pad_run_date: '',
      pad_total_amount : 0,

      disable_download_btn_flag: true,
      download_url: '',
      listingData: [],
      accounting_list: [],
      searched_pm_data: [],
      pm_trust_bank: {
        gl_account: 10100,
        gl_liability_account: 12106,
      },

      tenant_list : [],
      selected_tenant : '',
      selected_tenant_obj : {}

    };
  },
  components: {
    Layout,
    PageHeader,
    VueBootstrapTypeahead,
    ProgressDialog,
    flatPickr

  },

  created() {
    getBrokerageApi().configApi.pm_trust_bank_account().then(res => {
      this.pm_trust_bank = res.pm_trust
    })
  },

  mounted() {

    let today = moment()
    this.pm_month = today.format("YYYYMM")

    new Choices('#selected_type')

    this.pm_list(this.$route.query.property_id)

    this.updateAccountAmount()
  },

  watch: {
    selected_pm(new_pm_str) {
      this.onSearchPM(new_pm_str)
    },
    sortBy() {

      this.list_sort()
    },

    sortDesc() {
      this.list_sort()
    }


  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <b-modal centerd id="ProgressDiloag" title="Waiting...." size="lg" hide-footer hide-header-close
      no-close-on-backdrop no-close-on-esc>
      <ProgressDialog />
    </b-modal>

    <div class="row">

      <div class="col-xxl-12 col-lg-12">
        <div class="card">
          <div class="card-body">

            <form class="needs-validation" @submit.prevent="formSubmit">
              <div class="card border shadow-none mb-3">
                <div class="card-header d-flex align-items-center">
                 
                  <div class="flex-grow-1">
                    <h5 class="card-title">PM PAD</h5>
                  </div>
                </div>
                <div class="card-body">

                  <div class="row">


                    <div class="col-lg-2">
                      <div class="mb-3">
                        <label class="form-label" >PM Month</label>
                        <flat-pickr v-model="pm_month" placeholder="Select a month" class="form-control"
                          :config="{ dateFormat: 'Ym', altFormat: 'Y-m', ariaDateFormat: 'Y-m' }"
                          :class="{ 'is-invalid': $v.pm_month.$error }"></flat-pickr>
                        <div v-if="$v.pm_month.$error" class="invalid-feedback">
                          <span v-if="!$v.pm_month.required">This value is required.</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-2">
                      <div class="mb-3">
                        <label class="form-label" >Post Date</label>
                        <div class="mb-3">

                          <flat-pickr v-model="post_date" placeholder="Select a date" class="form-control"
                            :class="{ 'is-invalid': $v.post_date.$error }"></flat-pickr>
                          <div v-if="$v.post_date.$error" class="invalid-feedback">
                            <span v-if="!$v.post_date.required">This value is required.</span>
                          </div>
                        </div>

                      </div>
                    </div>

                    <div class="col-lg-2">
                      <div class="mb-3">
                        <label class="form-label" >PAD Run Date</label>
                        <div class="mb-3">

                          <flat-pickr v-model="pad_run_date" placeholder="Select a date" class="form-control"
                            :class="{ 'is-invalid': $v.pad_run_date.$error }"></flat-pickr>
                          <div v-if="$v.pad_run_date.$error" class="invalid-feedback">
                            <span v-if="!$v.pad_run_date.required">This value is required.</span>
                          </div>
                        </div>

                      </div>
                    </div>

                    <div class="col-lg-2">
                      <div class="mb-3">
                        <label class="form-label" >PAD Amount</label>
                        <div class="mb-3">
                          <input type="text" class="form-control" readonly v-model="pad_total_amount" />
                        </div>

                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="row mt-4">
                        <div class="justify-content-right gap-3 d-flex flex-wrap">
                          <button  class="btn btn-secondary w-sm" @click="$router.push({name : 'pm-property-list'})">
                            Cancel
                          </button>


                          <button type="submit" class="btn btn-primary w-sm" :disabled="!disable_download_btn_flag">
                            Setup PAD
                          </button>
                          <button type="button" class="btn btn-primary w-sm" :disabled="download_pad_btn_flag"
                            @click="download_pad_file()">
                            Download
                          </button>
                        </div>
                      </div>
                    </div>


                  </div>
                  <!-- end row -->

                 

                </div>
              </div>
              <!-- end card -->



              <div class="card border shadow-none mb-5 mt-5">
                <div class="card-header d-flex align-items-center gap-2">
               
                  <div class="flex-grow-1">
                    <h5 class="card-title">Rent Detail Review</h5>
                  </div>
                  <div class="col-md-6">
                  </div>

                  <div class="col-md-2">
                    <input class="form-control" v-model="filter" placeholder="Quick Filter PM" />
                  </div>

                  <div class="d-flex flex-grow-1 align-items-right gap-2">
                  
                    <button type="button" class="btn btn-primary w-sm" @click="setup_pad_form_file()">
                      Download List
                    </button>
                    <button type="button" class="btn btn-primary w-sm" @click="listingData = []">
                      Reset List
                    </button>
                  </div>
                </div>
                

                <div class="card-body">

                  <div class="row">

                    <div class="col-lg-2">
                      <div class="mb-3">
                        <label class="form-label" >Type</label>
                        <select class="form-control" v-model="selected_type" id="selected_type">
                          <option value="Rent" selected>Rent</option>
                          <option value="Owner Reserved">Reserved</option>
                          <option value="Secure Deposit">Deposit</option>
                          <option value="Other">Other</option>
                        </select>
                      </div>
                    </div>


                    <div class="col-lg-2">
                      <div class="mb-3">
                        <label class="form-label" >Property</label>
                        <vue-bootstrap-typeahead v-model="selected_pm" :value="selected_pm" :data="searched_pm_data"
                          :inputClass="{ 'is-invalid': $v.selected_pm.$error }" :serializer="s => s.str_id"
                          :foramterDisplay="s => s.str_id" @hit="onPMSelected($event)" autocomplete="off" />
                        <div v-if="$v.selected_pm.$error" class="invalid-feedback">
                          <span v-if="!$v.selected_pm.required">This value is required.</span>
                        </div>
                      </div>
                    </div>


                    <div class="col-lg-2">
                      <div class="mb-3">
                        <label class="form-label" >Tenant</label>
                        <vue-bootstrap-typeahead v-model="selected_tenant"  ref="selected_tenant" :data="tenant_list"
                          :inputClass="{ 'is-invalid': $v.selected_tenant.$error }" :serializer="s => s.name"
                          :foramterDisplay="s => s.name" @hit="onTenantSelected($event)" autocomplete="off" />
                        <div v-if="$v.selected_tenant.$error" class="invalid-feedback">
                          <span v-if="!$v.selected_tenant.required">This value is required.</span>
                        </div>
                      </div>
                    </div>


                    <div class="col-lg-1">
                      <div class="mb-3">
                        <label class="form-label" >Amount</label>
                        <div class="mb-3">

                          <input class="form-control" v-model="selected_amount"
                            :class="{ 'is-invalid': $v.selected_amount.$error }" />
                          <div v-if="$v.selected_amount.$error" class="invalid-feedback">
                            <span v-if="!$v.selected_amount.required">This value is required.</span>
                          </div>
                        </div>

                      </div>
                    </div>

                    <div class="col-lg-3">
                      <div class="mb-3">
                        <label class="form-label" >Note</label>
                        <input class="form-control" v-model="note" />
                      </div>
                    </div>


                    <div class="col-lg-2">
                      <div class="mt-4">
                        <b-button variant="primary" @click="onAddProperty">Add Entry</b-button>
                      </div>
                    </div>
                  </div>
                  <!-- end row -->


                  <b-tabs class="mt-n5 pt-5" content-class="card card-body mt-3" nav-class="nav-tabs-custom"
                    nav-wrapper-class="nav-tabs-custom">

                    <!--  Property Tab -->
                    <b-tab title="Property">
                      <div data-simplebar>
                        <div class="table-responsive mt-3">
                          <div class="table align-middle table-nowrap">
                            <b-table :items="listingData"
                              :fields="[{ 'key': 'Code', sortable: true }, 'Tenant', 'Amount', { 'key': 'Address', sortable: true }, 'Remove']"
                              responsive="sm" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter"
                              :filter-included-fields="['pm_id', 'address']" :per-page="listingData.length"
                              class="datatables">
                              <template #cell(Code)="data">
                                <feather :type="data.detailsShowing ? 'chevron-down' : 'chevron-right'"
                                  @click="data.toggleDetails"></feather>
                                {{ data.item.pm_id }}
                              </template>
                              <template #cell(Amount)="data">
                                ${{ data.item.amount.toLocaleString() }}
                              </template>
                              <template #cell(Tenant)="data">
                                {{ data.item.tenant }}
                              </template>

                              <template #cell(Address)="data">
                                {{ data.item.address }}
                              </template>
                              <template #cell(Remove)="data">
                                <div class="row icon-demo-content-10">
                                  <div class="col-xl-3 col-lg-4 col-sm-6" @click="removePMEntry(data.item)"><i
                                      class="uil-times font-size-4"></i></div>
                                </div>
                              </template>

                              <template #cell(Action)="data">
                                <b-dropdown variant="white" right toggle-class="btn-link text-dark shadow-none">
                                  <template v-slot:button-content>
                                    <i class="uil uil-ellipsis-h"></i>
                                  </template>
                                  <b-dropdown-item @click="updateRentAmount(data.item)">Update Amount</b-dropdown-item>
                                </b-dropdown>
                              </template>

                              <template v-slot:row-details="row">
                                <div class="table align-middle table-nowrap" style="margin-left:30px;">
                                  <b-table :items="row.item.rent_items"
                                    :fields="[{ key: 'Type' }, { key: 'Amount' }, { key: 'Action' }]" responsive="sm"
                                    :per-page="row.item.rent_items ? row.item.rent_items.length : 0"
                                    class="table-check col-md-10">
                                    <template #cell(Type)="data">
                                      {{ data.item.type }}
                                    </template>

                                    <template #cell(Amount)="data">
                                      ${{ data.item.amount.toLocaleString() }}
                                    </template>
                                    <template #cell(Action)="data">
                                      <div class="row icon-demo-content-10">
                                        <div class="col-xl-3 col-lg-4 col-sm-6"
                                          @click="removePMRentItem(row.item, data)">
                                          <i class="uil-times font-size-4"></i>
                                        </div>
                                      </div>
                                    </template>

                                  </b-table>
                                </div>
                              </template>

                            </b-table>
                          </div>
                        </div>
                      </div>
                    </b-tab>

                    <!--  Accounting Tab -->
                    <b-tab title="Accounting">
                      <div class="table-responsive mt-3">
                        <div class="table align-middle table-nowrap">
                          <b-table :items="accounting_list" :fields="['GL', 'Amount', 'Address']" responsive="sm"
                            :per-page="accounting_list.length" class="table-check">
                            <template #cell(GL)="data">
                              {{ data.item.account }}
                            </template>
                            <template #cell(Amount)="data">
                              ${{ data.item.amount.toLocaleString() }}
                            </template>

                            <template #cell(Address)="data">
                              {{ data.item.pm_id }} {{ data.item.address }}
                            </template>

                          </b-table>
                        </div>
                      </div>
                    </b-tab>
                  </b-tabs>



                </div>
              </div>
              <!-- end card -->

            </form>
            <!-- end form -->
          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
